import React from "react"
import Helmet from "react-helmet"

const logoImg = require("../dist/images/logo.png")


class PrivacyPage extends React.Component {

    render() {

        return (

            <div>
                <div className="application">
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>Kebijakan Privasi</title>
                    </Helmet>
                </div>

                 <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
          
                    <a className="navbar-brand logo-image" href="https://www.trouverty.com"><img src={logoImg} alt="alternative" /></a>
                    

                    <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                        <ul className="navbar-nav ml-auto">
                        
                        </ul>

                        <span className="nav-item social-icons">
                        <span className="fa-stack">
                            <a href="https://www.facebook.com/trouverty/">
                            <i className="fas fa-circle fa-stack-2x facebook"></i>
                            <i className="fab fa-facebook-f fa-stack-1x"></i>
                            </a>
                        </span>
                        <span className="fa-stack">
                                <a href="https://www.instagram.com/trouverty">
                                    <i className="fas fa-circle fa-stack-2x instagram"></i>
                                    <i className="fab fa-instagram fa-stack-1x"></i>
                                </a>
                            </span>
                        </span>
                    </div>
                </nav>

                <div className="cards-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>Kebijakan Privasi</h2>
                                <p className="text-justify tab">Kami <strong>Trouverty Indonesia</strong> (“Trouverty”) sangat memperhatikan privasi Anda dan juga privasi klien kami. Kami bertekad untuk melindungi dan menghargai hak pribadi pengguna kami. Kenyamanan dan rasa aman Anda sebagai pengguna dalam menggunakan situs web Trouverty Indonesia yang saat ini terletak di <a className="turquoise" href="https://www.trouverty.com">www.trouverty.com</a> dan aplikasi mobile Trouverty sangatlah penting bagi kami.</p>
                                <p className="text-justify tab">Dengan menggunakan atau mengunjungi Situs Web kami dan/atau menggunakan aplikasi mobile Trouverty, pengguna dianggap telah membaca dan dengan ini menyetujui kebijakan privasi dan setuju dengan ketentuan-ketentuannya. Trouverty Indonesia dapat mengubah dan memperbaharui Kebijakan Privasi ini, dan perubahan tersebut akan dimuat di halaman ini. Anda sebaiknya meninjau kembali Kebijakan Privasi ini secara berkala sehingga Anda dapat mengetahui adanya setiap perubahan.</p>
                                <p className="text-left"><strong>Informasi yang Kami Kumpulkan dan Tujuannya:</strong></p>
                                <p className="text-left">1. Informasi Akun dan Profil</p>
                                <p className="text-justify tab">Pada saat Anda membuat akun pada Layanan kami, kami dapat meminta informasi tertentu seperti alamat email dan password (kode identifikasi pengguna) yang benar. Anda dapat membuat suatu profil yang termasuk informasi seperti nama awal dan belakang, jenis kelamin, nomor telepon, informasi agency Anda, termasuk foto yang Anda inginkan untuk diunggah. Pada keadaan tertentu Anda juga dapat menghubungi pengguna-pengguna lain melalui profil mereka. Setiap informasi yang Anda bagikan harus berhati-hati mempertimbangkan resiko-resiko dalam membuat informasi pribadi tertentu, khususnya informasi seperti alamat atau informasi lokasi yang akurat, tersedia secara publik.</p>
                                <p className="text-left">2. Iklan dan Transaksi</p>
                                <p className="text-justify tab">Kami dapat mengatur informasi, termasuk informasi pribadi dan informasi kontak dan password (kode identifikasi pengguna) yang valid yang diperlukan untuk memungkinkan pertemuan pembeli dan penjual, untuk mengirimkan pesan atau berkomunikasi dengan pengguna lain. Sebagian besar dari informasi yang sama yang Anda gunakan untuk membuat akun atau profil dapat diminta kembali pada saat Anda membuat sebuah iklan. Setiap informasi yang Anda cantumkan dalam iklan adalah tanggung jawab Anda sendiri. Seperti halnya dengan profil Anda, Anda harus berhati-hati mempertimbangkan resiko-resiko dalam mencantumkan informasi pribadi Anda, seperti alamat atau infromasi lokasi yang akurat yang tersedia secara public dalam iklan.</p>
                                <p className="text-left">3. Komunikasi</p>
                                <p className="text-justify tab">Anda dapat menghubungi pengguna lain melalui fitur yang kami sediakan, dimana seluruh pemrosesan dari komunikasi antara Anda dan pengguna lain akan diatur secara otomatis melalui sistem kami. Komunikasi antara Anda dan pengguna lainnya juga dapat kami serahkan kepada pihak yang berwenang dalam hal terjadinya dugaan pelanggaran atas hukum.</p>
                                <p className="text-left">4. Periklanan dan Promosi</p>
                                <p className="text-justify tab">Kami dapat mengumpulkan informasi pribadi seperti nama dan informasi kontak pada saat Anda berpartisipasi dalam promosi pemasaran lain yang kami selenggarakan atau kami dukung dalam Layanan kami atau dalam situs pihak ketiga.</p>
                                <p className="text-left">Adapun tujuan dari pengumpulan informasi pribadi Anda dapat meliput tetapi tidak terbatas sebagai berikut:</p>
                                
                                <ol className="ol-list">
                                    <li className="text-justify">memastikan bahwa konten dari Situs Trouverty.com dan/atau aplikasi mobile Trouverty disajikan dengan cara yang paling efektif untuk Anda,</li>
                                    <li className="text-justify">untuk memenuhi permintaan Anda sebagai pengguna dan komunikasi dengan Anda,</li>
                                    <li className="text-justify">secara internal untuk menyediakan dan memperbaiki situs trouverty.com dan/atau aplikasi mobile Trouverty, layanan, fitur dan konten,</li>
                                    <li className="text-justify">statistik dan penelitian,</li>
                                    <li className="text-justify">menggunakan informasi pribadi Anda untuk keperluan pemasaran (termasuk pemasaran langsung) produk properti dan turunannya terkait dengan Trouverty Indonesia ataupun afiliasinya,</li>
                                    <li className="text-justify">untuk mempersonalisasi pengalaman Pengguna dan memberikan informasi dan penawaran dari kami atau pihak ketiga yang kami anggap sesuai dengan kebutuhan atau menarik bagi Pengguna, termasuk newsletter, pemasaran atau materi promosi dan informasi lainnya pada layanan dan produk yang ditawarkan oleh kami atau pihak ketiga.</li>
                                    <li className="text-justify">kebutuhan pengungkapan sebagaimana diperlukan oleh hukum, peraturan perundang-undangan yang berlaku dan/atau kebutuhan bagian yang berwenang di Trouverty Indonesia termasuk kepada afiliasinya.</li>
                                
                                </ol>
                                
                                <p className="text-left"><strong>Penggunaan, Pengelolaan, Transfer dan Pengungkapan Informasi Pribadi Anda</strong></p>
                                <p className="text-justify tab">Dengan menyampaikan informasi pribadi Anda kepada Trouverty Indonesia, berarti Anda telah menyetujui dan mengizinkan Trouverty Indonesia untuk dapat menggunakan, mengelola, mentransfer atau mengungkapkan informasi pribadi Anda untuk memenuhi kebutuhan Anda dan/atau Trouverty Indonesia sebagaimana tercantum pada bagian “Informasi yang Kami Kumpulkan dan Tujuannya” kepada salah satu pihak berikut (baik yang berada di dalam ataupun di luar negeri), antara lain:</p>
                                
                                <ol className="ol-list">
                                    <li className="text-justify">Setiap perusahaan terkait dengan Trouverty Indonesia.</li>
                                    <li className="text-justify">Setiap perantara properti yang memiliki perjanjian keagenan atau broker dengan Trouverty Indonesia.</li>
                                    <li className="text-justify">Setiap pihak terkait transaksi jual/beli dan/atau sewa/menyewa dengan Anda.</li>
                                    <li className="text-justify">Setiap asosiasi dan federasi industri properti yang ada dari waktu ke waktu.</li>
                                    <li className="text-justify">Setiap agen, afiliasi, kontraktor atau penyedia layanan pihak ketiga yang menyediakan administrasi, telekomunikasi, komputer, pembayaran atau jasa lainnya kepada Trouverty Indonesia dalam kaitannya dengan operasi bisnis Trouverty Indonesia.</li>
                                    <li className="text-justify">Setiap orang atau badan perusahaan atau instansi pemerintah atau regulator kepada siapa Trouverty Indonesia wajib mengungkapkan informasi pribadi tersebut di dalam rangka untuk memenuhi persyaratan hukum dan/atau peraturan perundang-undangan yang berlaku terkait dengan Trouverty Indonesia atau afiliasinya atau mitra bisnisnya.</li>
                                </ol>

                                <p className="text-left"><strong>Keamanan</strong></p>
                                <p className="text-justify tab">Kami berusaha untuk memastikan keamanan, integritas dan privasi dari informasi pribadi yang kami kumpulkan. Kami mengadakan penjagaan dan menggunakan tindakan keamanan yang wajar untuk melindungi informasi pribadi Anda dari akses, pengubahan dan pengungkapan yang tidak berwenang. Karyawan, kontraktor, agen dan penyedia layanan kami yang menyediakan layanan yang berkaitan dengan sistem informasi kami, wajib menjaga kerahasiaan dari setiap informasi pribadi yang kami simpan. Kami meninjau ulang dan memperbarui tindakan keamanan kami sehubungan dengan teknologi terbaru. Namun disayangkan, tidak terdapat transmisi data pada internet yang dapat dijamin aman sepenuhnya.</p>
                                <p className="text-left"><strong>Informasi Penting Lainnya</strong></p>
                                <p className="text-justify tab">Anda berhak untuk melakukan perubahan, pembaharuan, penambahan ataupun perbaikan terhadap informasi pribadi Anda. Di samping itu Anda juga dapat memperoleh akses terhadap informasi pribadi Anda yang kami simpan, dengan tunduk pada pengecualian-pengecualian yang diatur oleh hukum melalui media-media yang telah disediakan oleh Trouverty Indonesia.</p>
                                <p className="text-justify tab">Anda berhak untuk membatasi pemrosesan, tidak menyetujui atau menarik kembali persetujuan terhadap penggunaan, pengelolaan, transfer dan pengungkapan informasi pribadi Anda oleh Trouverty Indonesia sepanjang hal tersebut sesuai dan/atau tidak dibatasi oleh hukum dan peraturan perundang-undangan yang berlaku. Untuk melaksanakan hak ini, silakan menghubungi tim kami.</p>
                                
                                <p className="text-left"><strong>Kontak Kami</strong></p>
                                <p className="text-justify">Jika Anda memiliki pertanyaan berkaitan dengan Kebijakan Privasi Trouverty Indonesia, silakan menghubungi kami di <a className="turquoise" href="mailto:support@trouverty.com">support@trouverty.com.</a></p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <p className="p-small">Copyright © 2019 Trouverty</p>
                        </div>
                        </div>
                    </div>
                </div>

            </div>

            
        )
    }
}

export default PrivacyPage